import { Injectable } from '@angular/core';
import { User } from '@equipmyschool/emsadminweb-models';
import { AccountService } from 'app/server/server.module';
import {
  ClientDetails,
   PtdDispatchDetails,
  PreDispatchDetails,
  Proforma,
} from 'coadapi';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class sharedUserData {
  user: User;

  constructor(private server: AccountService) {}

  getUser(): User {
    this.server.iAmBehaviourSubject.subscribe((next) => {
      this.user = next;
    });
    return this.user;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SharedDispatchData {
  clientShare: ClientDetails = {};
  dispatchShare:  PtdDispatchDetails = {};
  preDispatchShare: PreDispatchDetails = {};
  sameAsConsigneeFlag: boolean = false;
  lastRoutePosition: string[]= [];
  links: { label: string; link: string; index: number }[] = [];
  iwdShare: PtdDispatchDetails = {};
  originShare: PtdDispatchDetails = {};
  /// Utils.mapDetailsToUpdate is used to populate the predispatchshare object with current details when the dispatch is being edited

  setData(tempClient, tempDispatch): void {
    this.clientShare = tempClient;
    this.dispatchShare = tempDispatch;
    return;
  }

  setIWDData(tempClient, tempOriginDispatch, tempIWD): void {
    this.clientShare = tempClient;
    this.originShare = tempOriginDispatch;
    this.dispatchShare = tempIWD;
    return;
  }

  reset(): void {
    this.clientShare = {};
    this.dispatchShare = {};
    this.preDispatchShare = {};
    this.sameAsConsigneeFlag = false;
    this.lastRoutePosition = [];
  }

  getClientSharedData(): ClientDetails {
    return this.clientShare;
  }

  getDispatchSharedData():  PtdDispatchDetails {
    return this.dispatchShare;
  }

  getOriginSharedData(): PtdDispatchDetails{
    return this.originShare;
  }

  getPreDispatchSharedData(): PreDispatchDetails {
    return this.preDispatchShare;
  }

  setPreDispatch(preDispatch: PreDispatchDetails) {
    this.preDispatchShare = preDispatch;
  }

  setPreDispatchClient(id: number): void {
    this.preDispatchShare.client = id;
  }

  setPreDispatchMethod(id: number): void {
    this.preDispatchShare.method = id;
  }

  setPreDispatchIncoterm(id?: number): void {
    this.preDispatchShare.incoterm = id;
  }

  setPreDispatchIncotermLocation(string?: string): void {
    this.preDispatchShare.incotermLocation = string;
  }

  setPreDispatchEtd(dateString: string): void {
    this.preDispatchShare.ets = dateString;
  }

  setPreDispatchEta(dateString: string): void {
    this.preDispatchShare.eta = dateString;
  }

  setPreDispatchDeliveryAddress(id: number): void {
    this.preDispatchShare.deliveryAddress = id;
  }

  getPreDispatchDeliveryAddress(): number {
    return this.preDispatchShare.deliveryAddress;
  }

  setPreDispatchConsigneeAddress(id: number): void {
    this.preDispatchShare.consigneeAddress = id;
  }

  setPreDispatchWarehouse(id: number): void {
    this.preDispatchShare.warehouse = id;
  }

  setPreDispatchSplitShipment(boolean: boolean): void {
    if (boolean) {
      this.preDispatchShare.shipmentCategory = 1;
    } else {
      this.preDispatchShare.shipmentCategory = 2;
    }
  }

  setPreDispatchShipmentCategory(id:number): void {
    this.preDispatchShare.shipmentCategory = id;
  }


  setPreDispatchDescriptive(id: number): void {
    this.preDispatchShare.descriptive = id;
  }

  setSameAsConsigneeFlag(boolean:boolean): void {
      this.sameAsConsigneeFlag = boolean;
  }

  getSameAsConsigneeFlag(): boolean {
    return this.sameAsConsigneeFlag;
  }

  setLastRoutePosition(string:string): void {
    if (this.lastRoutePosition[this.lastRoutePosition.length-1] !== string){
      this.lastRoutePosition.push(string);
    };          
  }

  getLastRoutePosition(): string {
    this.lastRoutePosition.pop();
    const backToHereString: string = this.lastRoutePosition[this.lastRoutePosition.length-1]
    return backToHereString;
  }

  setLinks(links: { label: string; link: string; index: number }[]): void {
    this.links = links;
  }

  getLinks(): { label: string; link: string; index: number }[] {
    return this.links;
  }

  getNextLink(label: string, skip: boolean): string {
    return "." + this.links.find((link) => link.index === this.getLinkIndex(label) + (skip ? 2 : 1)).link;
  }

  getLinkIndex(label: string): number {
    return this.links.find((link) => link.link.includes(label))?.index ?? -1;
  }

  setPreDispatchClientAgent(id: number): void {
    this.preDispatchShare.clientAgent = id;
  }

  getPreDispatchClientAgent(): number {
  return this.preDispatchShare.clientAgent;
  }

  setPreDispatchClientContact(id: number): void {
    this.preDispatchShare.clientContact = id;
  }

  getPreDispatchClientContact(): number {
  return this.preDispatchShare.clientContact;
  }

  setPreDispatchClientAgentContact(id: number): void {
    this.preDispatchShare.clientAgentContact = id;
  }

  getPreDispatchClientAgentContact(): number {
  return this.preDispatchShare.clientAgentContact;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SharedProformaData {
  proformaShare: Proforma = {};

  setData(tempProforma): void {
    this.proformaShare = tempProforma;
    return;
  }

  reset(): void {
    this.proformaShare = {};
  }

  getProformaSharedData(): Proforma {
    return this.proformaShare;
  }
}


@Injectable({
  providedIn: 'root'
})
export class StateService {
  drawerState$ = new BehaviorSubject<Map<number, boolean>>(null);
  flipState$ = new BehaviorSubject<Map<number, boolean>>(null);
}

@Injectable({
  providedIn: 'root'
})
export class ClientActionService {
  clientAction$ = new Subject();
}

@Injectable({
  providedIn: 'root'
})
export class DialogCompleteService {
  dialogComplete$ = new Subject<boolean>();
}

@Injectable({
  providedIn: 'root'
})
export class DDActionService {
  ddAction$ = new Subject();
}
