import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClientDetails,
  PtdClientService,
  ShipmentCompletion,
  WarehousePacked,
  PtdProformaService,
  SplitWarehouse,
  PtdDispatchDetails,
  FreightMethod,
  PtdDispatchService,
  PreDispatchDetails,
  Proforma,
  Warehouse,
  DeadlineUpdate,
  DispatchWarehouseDetails,
} from 'coadapi';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import {
  CdkDragDrop, transferArrayItem,
} from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { 
  ClientActionService, 
  DialogCompleteService, 
  SharedDispatchData, 
  SharedProformaData, 
  sharedUserData, 
  StateService 
} from '../../abstracts/_datasharing';
import Utils from '../../abstracts/_utils';
import { downloadSummaries } from '../../abstracts/_download-summaries';
import { MatIconRegistry } from '@angular/material/icon';
import { ConfirmationComponent } from '../../../dialogs/confirmation/confirmation.component';
import { IwdDeadlineComponent } from '../../../dialogs/iwd-deadline/iwd-deadline.component';
import { CustomDateAdapter } from '../../abstracts/_date-adapter';

export class TransferArrayComponent {
  origArray: Array<any>;
  targArray: Array<any>;
  origIndex: number;
  targIndex: number;
}

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientComponent implements OnInit {
  selectedClient$: Observable<ClientDetails>;
  selectedClientId: number;
  selectedDispatch$: Observable<PtdDispatchDetails>;
  hasProformaMovedSwitch: boolean;
  flipState = new Map();
  reloadState = new Map();
  isClosed: boolean;
  dialogRef: MatDialogRef<any>;
  passedClientAndDispatch = new Map();
  ukStateColorMap = new Map();
  usStateColorMap = new Map();
  stateColorMap = new Map();
  expansionState = new Map();
  firstBoot = true;
  sacrificialArray: SplitWarehouse[] = [];
  dispatchDropArray = [];
  dispatchDropCreateArray = [];
  iwdReturn: any[] = []; 

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private clientService: PtdClientService,
    public proformaService: PtdProformaService,
    public sanitizer: DomSanitizer,
    private sharedDispatchData: SharedDispatchData,
    private sharedUserData: sharedUserData,
    private sharedProformaData: SharedProformaData,
    private dispatchService: PtdDispatchService,
    public stateService: StateService,
    public clientActionService: ClientActionService,
    public dialogCompleteService: DialogCompleteService,
    private downloadSummaries: downloadSummaries,
    iconRegistry: MatIconRegistry
  ) 
  { 
    iconRegistry.addSvgIcon('looking-glass', sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/contentssvg.svg')),
    iconRegistry.addSvgIcon('edit-pen', sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/editsvg.svg')),
    iconRegistry.addSvgIcon('red-corner', sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/red-corner.svg')),
    iconRegistry.addSvgIcon('break-link', sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/disassociate.svg')),
    iconRegistry.addSvgIcon('trash-can', sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/trashsvg.svg')),
    iconRegistry.addSvgIcon('report', sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/report-final.svg')),
    iconRegistry.addSvgIcon('expand', sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/expand.svg')),
    iconRegistry.addSvgIcon('avatar', sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/avatar.svg')),
    iconRegistry.addSvgIcon('marker-agent', sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/marker-agent.svg')),
    iconRegistry.addSvgIcon('marker-primary', sanitizer.bypassSecurityTrustResourceUrl('app/coad-web/proforma-to-dispatch/images/marker-primary.svg'))
    // subscribe to the dialogcomplete subject to watch for the completed dialog close
    this.dialogCompleteService.dialogComplete$.subscribe({
      next: (result: boolean) => {
        if (result === true) {
          this.clientActionService.clientAction$.next();
        }
      }
    })
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.selectedClientId = Number(params.get('clientId'));
    });

    this.selectedClient$ = this.clientActionService.clientAction$.pipe(
      startWith(''),
      switchMap(() => {
        return this.clientService.getOneClient(this.selectedClientId);
      }),
      tap((res) => {
        if (this.firstBoot) {
          res.dispatches.forEach((dispatch) => {
            this.expansionState.set(dispatch.dispatchId, false);
            this.flipState.set(dispatch.dispatchId, false);
          });
          this.stateService.drawerState$.next(this.expansionState);
          this.stateService.flipState$.next(this.flipState);
          this.firstBoot = false;
        }
        res.dispatches.forEach((dispatch) => {
          for (let dwIndex = 0; dwIndex < dispatch.dispatchWarehouseDetails?.length ?? 0; dwIndex++) {
            const dw = dispatch.dispatchWarehouseDetails[dwIndex];
            this.iwdReturn.push([dispatch.dispatchId, this.calculateIWDPieValues(dw, dw.warehouse.warehouseId)])
          }
        });
        console.log(res);
      })
    );

    this.ukStateColorMap.set(0, 'hsl(207, 57%, 85%)'); /// EMS Light Blue
    this.ukStateColorMap.set(1, 'hsl(207, 57%, 75%)'); /// EMS Light Blue Monochromatic
    this.usStateColorMap.set(0, 'hsl(0, 82%, 78%)'); /// EMS Light Red
    this.usStateColorMap.set(1, 'hsl(0, 82%, 68%)'); /// EMS Light Red Monochromatic

  }

  getIndex(iwdReturn, dispatchId, warehouseId) {
    return iwdReturn.findIndex(x => x[0] === dispatchId && x[1].warehouseId === warehouseId);
  }

  dispatchIsClosed(isClosed) {
    if (isClosed) {
      return 'isclosed';
    }
  }

  hasConsignee(consignee,delivery) {
    if (consignee !== delivery) {
      return 'has-consignee';
    }
  }

  getColor(warehouseId: number) {
    if (warehouseId === 1) {
      return this.ukStateColorMap;
    } else {
      return this.usStateColorMap;
    }
  }

  getPrimary(warehousePackedCount: any, dispatch: any): any {
    let primaryPacked: WarehousePacked;
    warehousePackedCount.forEach((element) => {
      if (element.warehouseId === dispatch.dispatchWarehouse.warehouseId) {
        primaryPacked = element;
      }
    });
    return primaryPacked;
  }

  getSecondary(warehousePackedCount: any, dispatch: any): any {
    let secondaryPacked: WarehousePacked;
    warehousePackedCount.forEach((element) => {
      if (element.warehouseId !== dispatch.dispatchWarehouse.warehouseId) {
        secondaryPacked = element;
      }
    });
    return secondaryPacked;
  }

  openContentDialog(destination: number): void {
    this.router.navigate(['contents'], {
      relativeTo: this.route,
      skipLocationChange: true,
      queryParams: {
        destination: destination
      }
    });
  }

  openIwdContentDialog(): void {
    this.router.navigate(['iwd-contents'], {
      relativeTo: this.route,
      skipLocationChange: true,
    });
  }

  /**
 * Opens the dispatch dialog.
 * 
 * @param dispatch - The dispatch details.
 * @param client - The client details to pull ID, preferred agent, split shipments and descriptive from.
 * @param contactId - The contact ID of the user.
 * @param splitWarehouse - The split warehouse for use when we're dragging a proforma to create a dispatch. [optional] 
 */
  openDispatchDialog(dispatch: PtdDispatchDetails, client: ClientDetails, contactId:number, splitWarehouse?: SplitWarehouse): void {
    this.router.navigate(['dispatch'], {
      relativeTo: this.route,
      skipLocationChange: true,
      state: {
        ptdDispatch: dispatch,
        clientDetails: client,
        contactId: contactId,
        splitWarehouse: splitWarehouse ?? null,
        currentLast: this.getHighestDispatchNumber(client)
      }
    });
  }

  getHighestDispatchNumber(clientDetails: ClientDetails): number | null {
    if (!clientDetails.dispatches || clientDetails.dispatches.length === 0) {
        return null; // No dispatches available
    }

    return Math.max(...clientDetails.dispatches.map(dispatch => dispatch.dispatchNo));
  }

  openIWDDispatchDialog(
    originDispatch: PtdDispatchDetails, 
    client: ClientDetails,
    dispatch: PtdDispatchDetails
  ) {
    this.sharedDispatchData.setData(client, dispatch);
    this.sharedDispatchData.setIWDData(client, originDispatch, dispatch);
    this.router.navigate(['dispatch'], {
      relativeTo: this.route,
      skipLocationChange: true,
    });
  }

  /**
   * Opens the IWD deadline dialog.
   * 
   * @param originDispatch - The dispatch details.
   * @param iwds - The array of IWD split warehouses.
   */
  openIWDDeadlineDialog(
    originDispatch: PtdDispatchDetails,
    iwds: Array<SplitWarehouse>
  ) {
    // Get the dispatch warehouses
    this.dispatchService
      .getDispatchWarehouses(originDispatch.dispatchId)
      .subscribe((result: any) => {
        // Filter the duplicate warehouses
        const distinctWarehouses = result.filter(
          (warehouse, index, self) =>
            index === self.findIndex((t) => t.warehouseId === warehouse.warehouseId)
        );

        // If there is at least one warehouse
        if (distinctWarehouses.length >= 1) {
          // Open the IWD deadline dialog
          const iwdDeadlineRef = this.dialog.open(IwdDeadlineComponent, {
            id: "iwd-deadline-dialog",
            width: "600px",
            disableClose: false,
            panelClass: "confirmation-pane",
            data: {
              // Pass the distinct warehouses, dispatch and warehouse
              distinctWarehouses: distinctWarehouses,
              dispatch: originDispatch,
              warehouse: originDispatch.dispatchWarehouse.warehouseId,
            },
          });

          // When the dialog is closed
          iwdDeadlineRef.afterClosed().subscribe((res) => {
            // If the user made a selection
            if (res) {
              // Iterate over the selections
              res.forEach((element) => {
                // If the selection is not for the origin warehouse
                if (element.index > 0) {
                  // Create the new IWD deadline
                  const utcEts = CustomDateAdapter.changeDateToFlatDate(
                    element.selectedRangeValue.start
                  );
                  const utcEta = CustomDateAdapter.changeDateToFlatDate(
                    element.selectedRangeValue.end
                  );
                  const iwdDeadline = <DeadlineUpdate>{
                    dispatchId: originDispatch.dispatchId,
                    warehouseId: element.index,
                    ets: new Date(utcEts).toISOString(),
                    eta: new Date(utcEta).toISOString(),
                  };

                  // Update the IWD deadline
                  this.dispatchService
                    .setIWDDeadline(iwdDeadline)
                    .subscribe({
                      next: (result: any) => {
                        console.log(result);
                        this.clientActionService.clientAction$.next();
                      },
                      complete: () => {
                        console.log("update complete");
                        return true;
                      },
                    });
                }
              });
            }
          });
        }
      });
  }

  countIwdWarehouses(
    iwds: Array<SplitWarehouse>,
    originWarehouse: number
  ): Array<Warehouse> {
    let distinctWarehouse: Array<Warehouse> = [];
    iwds.forEach((iwd) => {
      if (iwd.warehouse.warehouseId !== 5) {        
        if (
          !distinctWarehouse.find(
            (x) => x.warehouseId === iwd.warehouse.warehouseId
          )
        ) {
          if (iwd.warehouse.warehouseId === originWarehouse) {
            distinctWarehouse.splice(0, 0, iwd.warehouse);
          } else {
            distinctWarehouse.push(iwd.warehouse);
          }
        }
      }
    });
    return distinctWarehouse;
  }

  /**
   * Opens the dispatch dialog with the given parameters
   * @param dispatch The dispatch details
   * @param client The client details
   * @param contactId The contact id of the dispatch (optional)
   * @param splitWarehouseProforma The split warehouse proforma (optional)
   */
  sharingDispatchData(
    client: ClientDetails,
    dispatch: PtdDispatchDetails,
    destination: number,
    contactId?: number,
    splitWarehouseProforma?: SplitWarehouse
  ) {
    this.sharedDispatchData.setData(client, dispatch);
    if (destination === 1) {
      // Open the dispatch dialog
      this.openDispatchDialog(dispatch, client, contactId, splitWarehouseProforma);
    } else if (destination === 2) {
      // Open the dispatch contents dialog
      this.openContentDialog(1);
    } else if (destination === 3) {
      // Open the iwd contents dialog
      this.openIwdContentDialog();
    }
  }

  sharingProformaData(
    proforma: Proforma
  ) {
    this.sharedProformaData.setData(proforma);
    this.openContentDialog(2);
  }

  public calculatePieValues(completion: ShipmentCompletion) {
    let runningTotal = 0;
    return completion.warehousePackedCount.map(
      (
        currentValue: WarehousePacked,
        index: number,
        internalWarehousePacked: WarehousePacked[]
      ) => {
        const results = {
          start:
            ((runningTotal + index === 0
              ? 0
              : internalWarehousePacked[index - 1].packed +
                internalWarehousePacked[index - 1].unpacked) /
              completion.dispatchLinecount) *
            100,
          packed: (currentValue.packed / completion.dispatchLinecount) * 100,
          unpacked:
            (currentValue.unpacked / completion.dispatchLinecount) * 100,
          over50packed:
            (currentValue.packed / completion.dispatchLinecount) * 100 > 50
              ? 1
              : 0,
          over50unpacked:
            (currentValue.unpacked / completion.dispatchLinecount) * 100 > 50
              ? 1
              : 0,
          percentRecieved:
            ((currentValue.packed + currentValue.unpacked) /
              completion.dispatchLinecount) *
            100,
        };
        runningTotal +=
          internalWarehousePacked[index].packed +
          internalWarehousePacked[index].unpacked;
        return results;
      }
    );
  }

  public calculateIWDPieValues(dwDetails: DispatchWarehouseDetails, warehouseId: number) {
    // console.log(dwDetails);
    let relIndex = dwDetails.warehousePackedCount.findIndex((x) => x.warehouseId === warehouseId);
    const results = {
      warehouseId: warehouseId,
      start: 0,
      packed:
        (dwDetails.warehousePackedCount[relIndex].packed /
          dwDetails.warehouseLineCount) *
        100,
      unpacked:
        (dwDetails.warehousePackedCount[relIndex].unpacked /
          dwDetails.warehouseLineCount) *
        100,
      over50packed:
        (dwDetails.warehousePackedCount[relIndex].packed /
          dwDetails.warehouseLineCount) *
          100 >
        50
          ? 1
          : 0,
      over50unpacked:
        (dwDetails.warehousePackedCount[relIndex].unpacked /
          dwDetails.warehouseLineCount) *
          100 >
        50
          ? 1
          : 0,
      percentRecieved:
        ((dwDetails.warehousePackedCount[relIndex].packed +
          dwDetails.warehousePackedCount[relIndex].unpacked) /
          dwDetails.warehouseLineCount) *
        100,
    };
    return results;
  }

  public addId(dispatchId: number) {
    if (this.dispatchDropArray.findIndex(x => x.includes(dispatchId)) < 0) {
      if (dispatchId === 0) {
        this.dispatchDropArray.push('dropCreate');
        return 'dropCreate';
      } else {
        this.dispatchDropArray.push('cdk-drop-list-' + dispatchId);
        this.dispatchDropArray.push('expansion-' + dispatchId);
        return 'cdk-drop-list-' + dispatchId;
      }  
    } else if (dispatchId > 0 ) {
      return this.dispatchDropArray.find(x => x.includes(dispatchId));
    } else {
      return 'dropCreate';
    };  
  }

  public dropDisassociate(event: CdkDragDrop<any>) {
    if (!event.isPointerOverContainer) {
      // console.log('pointer not over container');
      return;
    }
    console.log('disassociating');
    let originArray;
    let originData;
    const transferArrayObject: TransferArrayComponent = new TransferArrayComponent;
    const splitwarehouse: SplitWarehouse = {};
    console.log(
      'Came from: ',
      event.previousContainer.data[event.previousIndex]
    );
    const sacrificialArray: any[] = [];
    originArray = event.previousContainer.data;
    originData = event.previousContainer.data[event.previousIndex];
    transferArrayObject.origArray = originArray;
    transferArrayObject.targArray = sacrificialArray;
    transferArrayObject.origIndex = event.previousIndex;
    transferArrayObject.targIndex = 0;
    splitwarehouse.associatedDispatch = null;
    splitwarehouse.originPfi = originData.originPfi;
    splitwarehouse.warehouseLineCount = originData.warehouseLineCount;
    splitwarehouse.warehouseValue = originData.warehouseValue;
    splitwarehouse.comingFromDispatch = originData.associatedDispatch;
    splitwarehouse.warehouse = originData.warehouse;
    splitwarehouse.warehouse.region = originData.warehouseRegionName;

    this.proformaService
      .associateProforma(
        this.sharedUserData.getUser().ContactID,
        splitwarehouse
      )
      .subscribe({
        next: (result: any) => {
          console.log(result);
          transferArrayItem(
            transferArrayObject.origArray,
            transferArrayObject.targArray,
            transferArrayObject.origIndex,
            transferArrayObject.targIndex
          );
          this.clientActionService.clientAction$.next();
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
          console.log("drop-disassociate complete");
        },
      });
  }

  public drop(event: CdkDragDrop<any>, dispatch: PtdDispatchDetails) {
    if (!event.isPointerOverContainer) {
      // console.log('pointer not over container');
      return;
    }
    const splitwarehouse: SplitWarehouse = {};
    const dispatchId = dispatch.dispatchId;
    const originData = event.item.data;
    const originArray = event.previousContainer.data;
    const origIndex = event.previousContainer.data.findIndex(
      (pcItem: any) => pcItem.pfiId === originData.originPfi);
    const targetArray = event.container.data;
    const transferArrayObject: TransferArrayComponent = new TransferArrayComponent;

    if (dispatch.closedToIwd) {
      if (
        originData.warehouse.warehouseId !== dispatch.dispatchWarehouse.warehouseId
      ) {
        console.log('Nuhuhuuh, can\'t associate to this dispatch');
        return;
      }
    }
    let splitElement = document.getElementById("split-" + originData.originPfi + "-" + originData.warehouse.region);
    splitElement.className = splitElement.className.replace(originData.warehouse.region.toLowerCase(), "working ");

    console.log(
      'Came from: ',
      event.previousContainer.data.find(x => x.pfiId === originData.originPfi)
    );
    transferArrayObject.origArray = originArray;
    transferArrayObject.targArray = targetArray;
    transferArrayObject.origIndex = origIndex;
    transferArrayObject.targIndex = event.currentIndex;
    splitwarehouse.associatedDispatch = dispatchId;
    splitwarehouse.originPfi = originData.originPfi;
    splitwarehouse.warehouseLineCount = originData.warehouseLineCount;
    splitwarehouse.warehouseValue = originData.warehouseValue;
    splitwarehouse.comingFromDispatch = originData.comingFromDispatch;
    splitwarehouse.warehouse = originData.warehouse;
    splitwarehouse.warehouse.region = originData.warehouse.region;
    console.log("Going to: ", event.container.data[event.currentIndex]);

    this.proformaService
      .associateProforma(
        this.sharedUserData.getUser().ContactID,
        splitwarehouse
      )
      .subscribe({
        next: (result: any) => {
          console.log(result);
          if (typeof(originArray) !== 'undefined' && originArray[origIndex].pfiSplit.length === 0) {
            const primaryName =
              "proforma-" + splitwarehouse.originPfi.toString();
            const primaryTile = document.getElementById(primaryName);
            primaryTile.style.display = "none";
          transferArrayItem(
            transferArrayObject.origArray,
            transferArrayObject.targArray,
            transferArrayObject.origIndex,
            transferArrayObject.targIndex
          );
            this.calculatePieValues(dispatch.completion);
          }
          
          this.clientActionService.clientAction$.next();
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => {
          splitElement.className = splitElement.className.replace("working ", originData.warehouse.region + " ");
          console.log("drop-associate complete");
        },
      });
  }

  shuffle(id: Number) {
    if (this.expansionState && !this.expansionState.get(id)) {
      this.expansionState.set(id, true);
      this.stateService.drawerState$.next(this.expansionState);
    } else {
      this.expansionState.set(id, false);
      this.stateService.drawerState$.next(this.expansionState);
    }
  }

  flip(id: Number) {
    if (this.flipState && !this.flipState.get(id)) {
      this.flipState.set(id, true);
      this.stateService.flipState$.next(this.flipState);
    } else {
      this.flipState.set(id, false);
      this.stateService.flipState$.next(this.flipState);
    }
  }

  methodShortName(method: FreightMethod) {
    let matches = '';
    if (method.methodId === 1) {
      matches = 'Air';
    } else if (method.methodId === 2) {
      matches = 'Sea';
    } else if (method.methodId === 4) {
      matches = method.name;
    } else if (method.methodId === 70012) {
      matches = 'Virtual';
    } else if (method.methodId === 70014) {
      matches = 'EXW';
    } else {
      matches = method.name.match(/\b(\w)/g).join('');
    }

    return matches;
  }

  changeIWDState(dispatch: PtdDispatchDetails) {
    const iwdClosedSwitch = <HTMLInputElement>(
      document.getElementById('iwdClosedSwitch-' + dispatch.dispatchId)
    );
    const updateDetails: PreDispatchDetails = {};
    updateDetails.closedIwd = iwdClosedSwitch.checked;
    Utils.updateDispatch(dispatch, updateDetails, this.dispatchService);

    dispatch.dispatchProformas.forEach((value) => {
      if (
        value.warehouse.warehouseId === dispatch.dispatchWarehouse.warehouseId
      ) {
        // don't move a line that exists in the same warehouse
      } else {
        const splitwarehouse: SplitWarehouse = {};
        splitwarehouse.associatedDispatch = null;
        splitwarehouse.originPfi = value.originPfi;
        splitwarehouse.warehouse = value.warehouse;
        splitwarehouse.warehouseLineCount = value.warehouseLineCount;
        splitwarehouse.warehouse.region = value.warehouse.region;
        splitwarehouse.warehouseValue = value.warehouseValue;
        splitwarehouse.comingFromDispatch = dispatch.dispatchId;

        this.proformaService.associateProforma(this.sharedUserData.getUser().ContactID, splitwarehouse).subscribe({
          next: (result: any) => {
            console.log(result);
            this.clientActionService.clientAction$.next();
          },
          error: (err: any) => {
            console.log(err);
          },
          complete: () => {
            console.log('change-iwd-state complete');
          },
        });
      }
    });
  }

  disassociate(dispatchId: number) {
    const confirmDialogRef  = this.dialog.open(ConfirmationComponent, {
      id: 'confirmation-dialog',
      width: '600px',
      disableClose: true,
      panelClass: 'confirmation-pane',
      data: {
        titleText: 'Are you sure you want to disassociate all items from this dispatch?'
      }
    });

    confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dispatchService.disassociateProformas(dispatchId, this.sharedUserData.getUser().ContactID, ).subscribe({
          next: (result: any) => {
            console.log(result);
          },
          error: (err: any) => {
            console.log(err);
          },
          complete: () => {
            console.log('Disassociated');
            this.clientActionService.clientAction$.next();
          }
        });
      }
    })
  }

  getSummary(dispatch: PtdDispatchDetails) {
    this.downloadSummaries.getSummary(dispatch);
  }

  canDisassociate(dispatchProformas: Array<SplitWarehouse>) {
    let canCount = 0;
    dispatchProformas.forEach((split) => {
      if (split.splitPercentRecieved !== 100) {
        canCount++;
      }
    });
    if (canCount > 0) {
      return true;
    } else {
      return false;
    }
  }

  canDelete(dispatchProformas:  Array<SplitWarehouse>) {
    let canCount = 0;
    dispatchProformas.forEach((split) => {
      canCount++;
    });
    if (canCount === 0) {
      return true;
    } else {
      return false;
    }
  }

  deleteDispatch(dispatchId: number) {
    const confirmDialogRef  = this.dialog.open(ConfirmationComponent, {
      id: 'delete-dialog',
      width: '600px',
      disableClose: true,
      panelClass: 'delete-pane',
      data: {
        titleText: 'Are you sure you want to delete this dispatch?'
      }
    });

    confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dispatchService.deleteDispatch(dispatchId, this.sharedUserData.getUser().ContactID).subscribe({
          next: (result: any) => {
            console.log(result);
            let runloop = true;
            while (runloop) {
              if (this.dispatchDropArray.findIndex(x => x.includes(dispatchId)) < 0) {
                runloop = false;
              } else{
                this.dispatchDropArray.splice(this.dispatchDropArray.findIndex(x => x.includes(dispatchId)),1);
              }
            }
          },
          error: (err: any) => {
            console.log(err);
          },
          complete: () => {
            console.log('Deleted');
            this.clientActionService.clientAction$.next();
          }
        })
      }
    })
  }

  /**
   * Handles drop event for creating a new dispatch
   * @param event CdkDragDrop<any> event
   * @param client ClientDetails client object
   * @param route number route number - 1 being dispatch dialog
   */
  dropCreate(event: CdkDragDrop<any>, client: ClientDetails, route: number) {
    // Get data from origin container
    const originArray = event.previousContainer.data;
    const originData: SplitWarehouse = event.item.data;
    // Find proforma in origin container data
    const originProforma: Proforma = event.previousContainer.data.find(
      (x: any) => x.pfiId === originData.originPfi
    );
    const targetArray = event.container.data;
    const transferArrayObject: TransferArrayComponent = new TransferArrayComponent;

    // Set up transfer array
    transferArrayObject.origArray = originArray;
    transferArrayObject.targArray = targetArray;
    transferArrayObject.origIndex = originArray.findIndex(
      (pcItem: any) => pcItem.pfiId === originData.originPfi
    );
    transferArrayObject.targIndex = event.currentIndex;

    // Create dispatch details
    const dispatch: PtdDispatchDetails = {
      client: client.clientId,
      accountNo: client.accountNo,
      dispatchWarehouse: originProforma.pfiLeavingFromWarehouse,
      isClosed: false,
      dispatchDeliveryAddress: originProforma.pfiDeliveryAddress,
      dispatchConsigneeAddress: originProforma.pfiConsigneeAddress,
      dispatchFreightMethod: originProforma.pfiFreightMethod,
      incoterm: originProforma.pfiIncoterm,
      closedToIwd: false,
      dispatchCurrency: originProforma.pfiCurrency,
      clientAgent: originProforma.pfiAgent,
      clientAgentContact: originProforma.pfiAgentContact
    };
    // If there is only one contact for the proforma, set as dispatch contact
    if (originProforma.pfiContacts.length === 1) {
      dispatch.clientContact = originProforma.pfiContacts[0];
    }

    // Call sharing service to share data
    this.sharingDispatchData(
      client,
      dispatch,
      route,
      this.sharedUserData.getUser().ContactID,
      originData
    );

    console.log(this.dispatchDropCreateArray, originProforma);
  }
}
